export default [
  // {
  //   path: '/login/test',
  //   name: 'login',
  //   meta: {
  //     layout: 'blank',
  //     requiresVisitor: true
  //   },
  //   component: () => import('@/views/authentication/Login.vue')
  // },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    component: () => import('@/views/authentication/TestLogin.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    component: () => import('@/views/authentication/SignUp.vue')
  },
  {
    path: '/social-callback/:provider',
    name: 'social-callback',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    props: true,
    component: () => import('@/views/authentication/SocialCallback.vue')
  },
  {
    path: '/forgotpassword',
    name: 'forgotpassword',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    component: () => import('@/views/authentication/ForgotPassword.vue')
  },
  {
    path: '/verifycode',
    name: 'verifycode',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    component: () => import('@/views/authentication/VerifyCode.vue')
  },
  {
    path: '/password/reset/:token/:email',
    name: 'newpassword',
    meta: {
      layout: 'blank',
      requiresVisitor: true
    },
    component: () => import('@/views/authentication/NewPassword.vue')
  },
]