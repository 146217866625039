/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import router from '../../router';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('accessToken') ?? null,
    refresh_token: localStorage.getItem('refreshToken') ?? null,
    access_token: localStorage.getItem('accessToken') ?? null,
    loggedInUser: JSON.parse(localStorage.getItem('user_info')) ?? {},
    isAuthenticated: localStorage.getItem('accessToken') ? true : false,
    loader: false,
    loginError: null,
    userInfo: JSON.parse(localStorage.getItem('user_info')) ?? {},
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token,
  },
  mutations: {
    SET_TOKEN(state, token) {
      localStorage.setItem('accessToken', token);
      state.token = token;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refresh_token = refreshToken;
      localStorage.setItem('refreshToken', refreshToken);
    },
    SET_LOGED_IN_USER(state, user) {
      state.loggedInUser = user;
      state.isAuthenticated = true;
    },
    CLEAR_USER_DATA(state) {
      state.refresh_token = '';
      state.access_token = '';
      state.loggedInUser = {};
      state.userInfo = {};
      state.isAuthenticated = false;
    },
    SET_LOADER(state, loader) {
      state.loader = loader;
    },
    STOP_LOGIN(state, errorLogin) {
      state.loader = false;
      state.loginError = errorLogin;
    },
    SET_USER_INFO(state, info) {
      state.userInfo = info;
      localStorage.setItem('user_info', JSON.stringify(info));
    },
  },
  actions: {
    async login({ commit, getters }, userCredentials) {
      const loginEndPoint = 'login';
      commit('SET_LOADER', true);
      try {
        await axios.post(loginEndPoint, userCredentials).then(res => {
          if (res.status === 200) {
            localStorage.setItem('accessToken', res.data.response.access_token);
            localStorage.setItem('refreshToken', res.data.response.refresh_token);
            localStorage.setItem('user_info', JSON.stringify(res.data.response.user));
            commit('SET_USER_INFO', res.data.response.user);
            commit('SET_LOGED_IN_USER', res.data.response.user);
            router.push({ name: 'order' });
            // window.location.href = '/'
          }
        }).finally(() => {
          commit('SET_LOADER', false);
        });
      } catch (e) {
        if (e) {
          commit('STOP_LOGIN', 'Please enter a valid username and password');
        }
      }
    },
    async socialLogin({ commit }, payload) {
      const loginEndPoint = `/social/${payload.provider}/callback`;
      commit('SET_LOADER', true);
      try {
        await axios.post(loginEndPoint, payload).then(res => {
          console.log(res);
          if (res.status === 200) {
            localStorage.setItem('accessToken', res.data.response.access_token);
            localStorage.setItem('refreshToken', res.data.response.refresh_token);
            localStorage.setItem('user_info', JSON.stringify(res.data.response.user));
            commit('SET_USER_INFO', res.data.response.user);
            commit('SET_LOGED_IN_USER', res.data.response.user);
            router.push({ name: 'order' });
          }
        }).finally(() => {
          commit('SET_LOADER', false);
        });
      } catch (e) {
        if (e) {
          commit('STOP_LOGIN', 'Please enter a valid username and password');
        }
        router.push({ name: 'login' });
      }
    },
    async refreshToken({ state, commit }) {
      const refreshTokenEndPoint = 'refresh-token';
      try {
        await axios.post(refreshTokenEndPoint, { refresh_token: localStorage.getItem('refreshToken') }).then(res => {
          if (res.status === 200) {
            commit('SET_TOKEN', res.data.data.access_token);
            commit('SET_REFRESH_TOKEN', res.data.data.refresh_token);
          }
        });
      } catch (e) {
        // console.log(e)
      }
    },
    async logout({ state, commit }) {
      const logoutEndPoint = 'logout';
      try {
        const { data } = await axios.post(logoutEndPoint);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user_info');
        commit('CLEAR_USER_DATA');
        router.push({ name: 'login' });
      } catch (error) {
        console.log(error);
      }
    }
  },
};
