import Vue from 'vue'
import Vuex from 'vuex'
import login from './auth/login'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    locale: '',
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale
    }
  },
  actions: {
  },
  modules: {
    login
  },
  strict: process.env.DEV
})
