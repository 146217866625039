<template>
  <v-app>
    <v-main>
      <vertical-nav />
      <nav-bar />
      <main class="main">
        <slot />
      </main>
    </v-main>
  </v-app>
</template>

<script>
import TrFooter from '../../../components/layouts/Footer.vue';
import NavBar from "../../../components/layouts/NavBar.vue";
import VerticalNav from '../../components/VerticalNav.vue';
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { NavBar, TrFooter, VerticalNav },
};
</script>,
    VerticalNav

<style>
.main {
  min-height: 100%;
}
</style>