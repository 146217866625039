/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import axios from 'axios';
import store from '../store/index';
import router from '../router/index';

export default function axiosSetUp() {
  // axios.defaults.baseURL = 'http://trjim.test/api/';
    axios.defaults.baseURL = 'https://customer2.trjim.com/api/'
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      // config.headers.setAttribute('Authorization', token)
    }
    return config;
  }, error => Promise.reject(error));
}

axios.interceptors.response.use(response => response, async error => {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url.includes('refresh-token')) {
    localStorage.removeItem('accessToken');
    router.push('/login');
    return Promise.reject(error);
  } if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    await store.dispatch('login/refreshToken');
    return axios(originalRequest);
  }
  return Promise.reject(error);
});
