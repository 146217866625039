<template>
  <div id="header-bar">
    <v-container>
      <v-row>
        <v-navigation-drawer disable-resize-watcher v-model="sidebar" app>
          <v-list>
            <v-list-item
              v-for="item in menuItems"
              :key="item.title"
              :to="item.path"
            >
              <!-- <v-list-item-action> </v-list-item-action> -->
              <v-list-item-content>{{ item.title }}</v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

        <v-app-bar fixed class="p-6">
          <span class="hidden-sm-and-up">
            <v-app-bar-nav-icon @click="sidebar = !sidebar">
            </v-app-bar-nav-icon>
          </span>
          <v-spacer></v-spacer>
          <v-toolbar-title>
            <router-link to="/" tag="span" style="cursor: pointer">
              <v-img :src="require('@/assets/images/logo.svg')" width="100" />
            </router-link>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-xs-only">
            <v-btn
              text
              v-for="item in menuItems"
              :key="item.title"
              :to="item.path"
              class="menu-nav"
              active-class="active-menu"
            >
              {{ item.title }}
            </v-btn>
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <app-bar-18n class="menu-nav"></app-bar-18n>
          <v-spacer></v-spacer>
          <v-toolbar-items
            class="hidden-xs-only"
            v-if="!$store.state.login.isAuthenticated"
          >
            <v-btn text class="menu-nav" to="/login"
              >{{ $t("Sign_in") }}
            </v-btn>
          </v-toolbar-items>
          <v-menu v-else bottom min-width="200px" rounded offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon x-large v-on="on">
                <v-avatar
                  v-if="!$store.state.login.userInfo.avatar"
                  color="deep-purple"
                  size="32"
                >
                  <span class="white--text body-2">{{
                    initialsName($store.state.login.userInfo.name)
                  }}</span>
                </v-avatar>
                <v-avatar v-else color="deep-purple" size="32">
                  <img :src="$store.state.login.userInfo.avatar" :alt="initialsName($store.state.login.userInfo.name)" />
                </v-avatar>
              </v-btn>
            </template>
            <v-card>
              <v-list-item-content class="justify-center">
                <div class="mx-auto text-center">
                  <v-avatar color="deep-purple" size="32" v-if="!$store.state.login.userInfo.avatar">
                    <span class="white--text body-2">{{
                      initialsName($store.state.login.userInfo.name)
                    }}</span>
                  </v-avatar>
                  <v-avatar v-else color="deep-purple" size="32">
                    <img :src="$store.state.login.userInfo.avatar" :alt="initialsName($store.state.login.userInfo.name)" />
                  </v-avatar>
                  <h3>{{ $store.state.login.name }}</h3>
                  <p class="text-caption mt-1">
                    {{ $store.state.login.email }}
                  </p>
                  <v-divider class="my-1"></v-divider>
                  <v-btn
                    text
                    block
                    class="caption"
                    :to="{ name: 'user-admin' }"
                  >
                    {{$t('user_menu_text_1')}}
                  </v-btn>
                  <v-btn
                    text
                    block
                    class="caption"
                    :to="{ name: 'user-settings' }"
                  >
                    {{$t('user_menu_text_2')}}
                  </v-btn>
                  <v-divider class="my-1"></v-divider>
                  <v-btn text block class="caption" @click="logout">
                    {{$t('user_menu_text_3')}}
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-row>
    </v-container>
    <div class="container-fluid">
      <div class="border-bo"></div>
    </div>
  </div>
</template>

<script>
import AppBar18n from "./AppBar18n.vue";
//import HelloWorld from "./components/HelloWorld";

export default {
  components: { AppBar18n },
  name: "App",
  data() {
    return {
      sidebar: false,
      menuItems: [
        { title: this.$i18n.t("home"), path: "/" },
        { title: this.$i18n.t("Services"), path: "/services" },
        { title: this.$i18n.t("About"), path: "/about" },
        { title: this.$i18n.t("Contact_Us"), path: "/contact" }
      ]
    };
  },
  methods: {
    initialsName(name) {
      return name
        .match(/(^\S\S?|\b\S)?/g)
        .join("")
        .match(/(^\S|\S$)?/g)
        .join("")
        .toUpperCase();
    },
    logout() {
      this.$store.dispatch("login/logout");
    }
  }
};
</script>
<style lang="scss" scopped>
.menu-nav {
  font-size: 1.5rem !important;
  color: #808080;
  .v-btn__content {
    font-size: 1.6rem;
    font-weight: 500;
  }
}
.v-btn--active {
  color: #6121d6 !important;
}
.v-btn--active:before {
  opacity: 0.18;
  background-color: transparent !important;
}

.border-bo {
  border-bottom: 1px solid #eee;
}

#header-bar {
  height: 100px;
}

.v-toolbar__content,
.v-app-bar.v-toolbar {
  height: 79px !important;
}

.menu-nav.v-btn span {
  color: #808080;
}

.menu-nav.v-btn.active-menu span {
  color: #6121d6;
}
</style>
