<template>
  <nav class="mt-16">
    <!-- <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
    </v-app-bar> -->
    <v-navigation-drawer v-model="drawer" light app right class="mt-16">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="96" color="deep-purple" v-if="!$store.state.login.userInfo.avatar">
            <span class="title white--text">{{ initialsName($store.state.login.userInfo.name) }}</span>
          </v-avatar>
          <v-avatar size="96" color="deep-purple" v-else>
            <img :src="$store.state.login.userInfo.avatar" :alt="initialsName($store.state.login.userInfo.name)" />
          </v-avatar>
          <p class="subheading mt-1 text-center">{{  $store.state.login.userInfo.name }}</p>
        </v-flex>
      </v-layout>
      <v-list flat>
        <v-list-item
          router
          to="/tr-admin/user-dashboard"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_1')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router
          to="/tr-admin/user-settings"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_2')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="border"
          @click="logout"
        >
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_3')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
export default {
  data: () => ({
    drawer: true,
    links: [
      { icon: 'dashboard', text: 'الطلبات', route: '/tr-admin/user-dashboard' },
      { icon: 'settings', text: 'اعدادات الحساب', route: '/tr-admin/user-settings' },
      { icon: 'person', text: 'تسجيل خروج', route: '#', action: 'logout' }
    ]

  }),
  methods: {
    initialsName(name) {
      return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    },
    logout() {
      this.$store.dispatch('login/logout')
    }
  }
}
</script>
<style scoped>
.border {
  border-left: 4px solid #0ba518;
}
</style>
