import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const opts = {
  rtl: localStorage.locale === "ar" ? true : false,
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      options: { customProperties: true },
      light: {
        primary: '#6214DF',
        black: '#202020',
      }
    }
  }
};

export default new Vuetify(opts);
