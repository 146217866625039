<template>
  <div id="floating-social">
    <a href="https://www.facebook.com/trjimnow" target="_blank"  title="Check me on facebook"> <img class="py-2" src="@/assets/images/social/facebook.png"> </a>
    <a href="https://twitter.com/trjimnow" target="_blank"  title="Check me on twitter"> <img class="py-2" src="@/assets/images/social/twitter.png"> </a>
      <a href="https://www.linkedin.com/company/trjimsite" target="_blank"   title="Check me on linkedin"><img class="py-2" src="@/assets/images/social/linkedin.png"></a>
  </div>
</template>

<script>
// import {
//   Facebook,
//   Twitter,
//   Linkedin,
// } from "vue-socialmedia-share";
export default {
  // components: { Facebook, Twitter, Linkedin },
  data() {
    return {
      url: "www.trjim.com"
    };
  }
}
</script>

<style scopped lang="scss">
#floating-social {
  border-radius: 0px 40px 40px 0;
  position: fixed;
  top: 25%;
  left: -10px;
  background-color: #daf3fa;
  padding: 1.2rem;
  z-index: 1;
  display: grid;
  span {
    display: block;
    cursor: pointer;
  }
}
</style>