<template>
  <v-app>
      <main class="main">
        <nav-bar />
        <floating-social />
        <slot />
        <tr-footer />
      </main>
  </v-app>
</template>

<script>
import FloatingSocial from '../../../components/layouts/FloatingSocial.vue';
import TrFooter from '../../../components/layouts/Footer.vue';
import NavBar from "../../../components/layouts/NavBar.vue";
export default {
  components: { NavBar, TrFooter, FloatingSocial },
};
</script>

<style>
.main {
  min-height: 100%;
}
</style>