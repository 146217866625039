export default [ 
  {
    path: '/tr-admin/user-dashboard',
    name: 'user-admin',
    component: () => import('@/views/admin-user/UserAdmin.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    },
  },
  {
    path: '/tr-admin/user-settings',
    name: 'user-settings',
    component: () => import('@/views/admin-user/UserSettings.vue'),
    meta: {
      layout: 'dashboard',
      requiresAuth: true
    },
  },
]